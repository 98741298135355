<template>
	<div id="tu-order">
		<!-- fa-fighter-jet -->
		<el-row :gutter="25">
			<el-col :span="16">
				<el-form label-position="top" >
					<div class="request">
						<div class="title"><i class="fa fa-fighter-jet"></i>{{ $t('course.request-a-lesson') }}</div>
						<div class="sub-title">{{ $t('course.first-lesson') }}</div>
						<div class="subject">
							<div class="tips">{{ $t('course.want-a-learn') }}</div>
							<div class="detail">
								<el-cascader v-model="form.subject_id" :options="subject_options" clearable />
							</div>
						</div>
						<div class="for" v-if="form.subject_id">
							<div class="tips">{{ $t('course.who') }}</div>
							<div class="detail" v-if="!isAddAcount">
								<template v-for="item, index in profile_data">
									<div @click="selectProfile(item)" v-if="!!item.nickname" :class="{current: item.profile_id==form.profile_id}">{{ item.nickname }}</div>
								</template>
							</div>
							<div class="detail" v-else>
								<el-button type="info" size="small" @click="$router.push('/st/information?to=order')">{{ $t('Perfect Information') }}</el-button>
							</div>
						</div>
						<div class="book" v-if="form.subject_id">
							<div class="tips">{{ $t('course.when-first-lesson') }}</div>
							<div class="detail">
								<el-row :gutter="20">
									<el-col :span="18">
										<el-form-item :label="$t('date')">
									      <el-input v-model="book_time_show" @click="onTutorDate" placeholder="dd/mm/yyyy hh:mm" readonly></el-input>
									    </el-form-item>
									</el-col>
									<el-col :span="6">
										<el-form-item :label="$t('duration')">
									      <el-input v-model="form.duration" readonly></el-input>
									    </el-form-item>
									</el-col>
								</el-row>							    
							</div>
						</div>
						<div class="content" v-if="form.subject_id">
							<div class="tips">{{ $t('course.introduce-yourself') }}</div>
							<div class="detail">
								<el-input
									v-model="form.content"
									maxlength="500"
									rows="6"
									:placeholder="$t('course.illustrate1') + '\r\n\r\n' + $t('course.illustrate2')"
									show-word-limit
									type="textarea"
								/>
							</div>
						</div>
						<div class="button" v-if="form.subject_id">
							<el-button type="primary" @click="onRequestLesson">{{ $t('course.request-lesson') }}</el-button>
						</div>
					</div>
				</el-form>
			</el-col>
			<el-col :span="8">
				<div class="tutor">
					<div class="avatar">
						<div>
							<el-avatar :size="100" v-if="tutor.avatar_url" :src="tutor.avatar_url"></el-avatar>
							<el-avatar :size="100" v-else>{{ tutor.slot }}</el-avatar>
						</div>
						<div>
							<div>{{ tutor.nickname }}</div>
							<div>{{ tutor.currency }}{{ tutor.rate }}<span>/hr</span></div>
							<div><el-rate v-model="tutor.star" disabled size="large"></el-rate></div>
						</div>
					</div>
					<el-divider></el-divider>
					<div class="note"><strong>{{ $t('course.about-your-lesson') }}</strong></div>
					<div class="detail">
						<div>
							<div>{{ $t('course.about-your-lesson') }}</div>
							<div>{{ subject_name }}</div>
						</div>
						<div>
							<div>{{ $t('course.timezone') }}</div>
							<div>{{ getCurrentTimeZone() }}</div>
						</div>
						<div>
							<div>{{ $t('date') }}</div>
							<div>{{ book_show[0] }}</div>
						</div>
						<div>
							<div>{{ $t('course.start-time') }}</div>
							<div>{{ book_show[1] }}</div>
						</div>
						<div>
							<div>{{ $t('duration') }}</div>
							<div>{{ form.duration }}</div>
						</div>
						<div>
							<div>{{ $t('for') }}</div>
							<div>{{ profile_show }}</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<TutorAvailability ref="tutoravailability" @change="handleSelectTime" />
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import StudentApi from '@/api/student'
import TeacherApi from '@/api/teacher'
import CourseApi from '@/api/course'
import TimeControl from '@/utils/time-control'
import TutorAvailability from '@/components/TutorAvailability'

export default {
	name: "tu-order",
	components: {
		TutorAvailability
	},
	data() {
		return {
			order: {},
			form: {
				tutor_id: 0,
				subject_id: '',
				profile_id: 0,
				book_time: '',
				duration: '01:00',
				content: ''
			},
			subject_options: [],
			profile_data: [],
			tutor: {},
			dialogVisible: false,
			availability: '',
			isAddAcount: true
		}
	},
	created() {
		this.order = this.$store.state.order.yforder;
		if(!!!this.order || !!!this.order.id) {
			this.$router.push({path: "/find"})
		}
		this.$store.dispatch('GetSubjectData').then(res => {
			this.getTutorInfo();
		});		
	},
	computed: {
		...mapGetters([
			'subjectData'
		]),
		book_time_show() {
			return this.form.book_time > 0 ? TimeControl.getFormatTime(this.form.book_time, 'DD/MM/YYYY HH:mm') : '';
		},
		subject_name() {
			if(!!this.form.subject_id) {
				let subject_id = this.form.subject_id[1];
				for(let i in this.subjectData.subject) {
					for(let k in this.subjectData.subject[i].children) {
						if(subject_id == k) {
							return this.subjectData.subject[i].name + '(' + this.subjectData.subject[i].children[k].name + ')';
						}
					}
				}
			}
			return '--';
		},
		book_show() {
			if(this.form.book_time > 0) {
				return [
					TimeControl.getFormatTime(this.form.book_time, 'DD/MM/YYYY'),
					TimeControl.getFormatTime(this.form.book_time, 'HH:mm')
				];
			}
			return  '--';
		},
		profile_show() {
			if(!!this.form.profile_id) {
				for(let i in this.profile_data) {
					if(this.profile_data[i].profile_id == this.form.profile_id) {
						// return this.profile_data[i].first_name + ' ' + this.profile_data[i].last_name;
						return this.profile_data[i].nickname;
					}
				}
			}
			return 'Me';
		}
	},
	methods: {
		handleBaseData() {
			for(let i in this.subjectData.subject) {
				let subject = {
					value: this.subjectData.subject[i].id,
					label: this.subjectData.subject[i].name,
					children: []
				}
				for(let k in this.subjectData.subject[i].children) {
					if(!!this.tutor && !!this.tutor.subject) {
						if(this.tutor.subject.indexOf(this.subjectData.subject[i].children[k].id) == -1) {
							if(this.tutor.subject.indexOf(this.subjectData.subject[i].children[k].id.toString()) == -1) {
								continue;
							}
						}
					}
					subject.children.push({value: this.subjectData.subject[i].children[k].id, label: this.subjectData.subject[i].children[k].name});
				}
				if(subject.children.length > 0) {
					this.subject_options.push(subject)
				}
			}
			this.form.tutor_id = this.order.id;
			this.form.book_time = this.order.time > 0 ? this.order.time : 0

			this.getProfileList();
		},
		getProfileList() {
			StudentApi.profile().then(res => {
				this.profile_data = res.data;
				for(let i in this.profile_data) {
					if(this.profile_data[i].nickname) {
						this.isAddAcount = false
					}
					if(!!this.profile_data[i].is_main && this.form.profile_id <= 0) {
						this.form.profile_id = this.profile_data[i].profile_id;
						this.form.content = this.profile_data[i].content;
					}
				}
			})
		},
		selectProfile(value) {
			value == value || {};

			this.form.profile_id = value.profile_id;
			this.form.content = value.content || '';
		},
		getTutorInfo() {
			TeacherApi.info({uid: this.order.id}).then(res => {
				this.tutor = res.data;
				this.handleBaseData();
			});
		},
		onTutorDate() {
			this.$refs.tutoravailability.open(this.order.id);
		},
		handleSelectTime(value) {
			this.form.book_time = value > 0 ? value : 0;
		},
		getCurrentTimeZone() {
			return TimeControl.getTimeZone();
		},
		onRequestLesson() {
			if(!this.form.tutor_id) {
				this.$message.error(this.$t('course.enter-choose-teacher'));
				return ;
			}
			if(!this.form.subject_id) {
				this.$message.error(this.$t('course.enter-study-course'));
				return ;
			}
			if(this.form.book_time <= 0) {
				this.$message.error(this.$t('course.enter-date-time'));
				return ;
			}
			if(!this.form.content) {
				this.$message.error(this.$t('course.want-to-study'));
				return ;
			}
			// console.log(this.form);
			let params = {
				uid: this.form.tutor_id,
				subject_id: this.form.subject_id[1],
				subscribe: [this.form.book_time],
				content: this.form.content,
				profile_id: this.form.profile_id
			}
			CourseApi.order(params).then(res => {
				this.$store.commit('SET_YF_ORDER_DATA', {});
				this.$router.push({path: "/course/pay", query: {pid: res.data.package_id}});
			});
		}
	}

}
</script>
<style lang="less">
#tu-order {
	font-family: Faro,sans-serif;

	.request {
		width: 100%;
		margin: 50px 25px;

		.title {
			font-size: 3rem;
		}

		.sub-title {
			font-size: 1.45rem;
			font-weight: 500;
			padding: 6px 0;
		}

		.tips {
			font-size: 1rem;
			font-weight: 600;
			padding: 5px 0;
		}

		.subject {
			margin-top: 15px;
			.detail {
				padding: 10px 0;

				.el-cascader {
					width: 100%;
				}
			}
		}

		.for {
			.detail {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 6px 0;

				& > div {
					border: 1px solid var(--el-color-info-light);
					border-radius: 4px;
					padding: 18px 55px;
					margin: 0 12px 12px 0;
					cursor: pointer;
					font-size: 13px;
					font-weight: 600;
				}

				& > div.current {
					border: 1px solid var(--el-color-primary);
				}

				& > div:hover {
					background: var(--el-color-info-lighter);
					// border: 1px solid var(--el-color-info-light);
				}
			}
		}

		.book {
			.detail {

			}
		}

		.content {
			.detail {
				padding: 5px 0;

				::-webkit-input-placeholder{
					font-style: italic;
				}
				::-moz-placeholder{
					font-style: italic;
				}
				:-ms-input-placeholder{
					font-style: italic;
				}
			}
		}

		.button {
			padding: 10px 0;

			.el-button {
				width: 35%;
			}
		}

		.el-form-item {
			.el-form-item__label {
				padding: 0;
				font-size: initial;
				font-weight: 500;
			}
		}
	}

	.tutor {
		background: var(--el-color-warning-lighter);
		margin: 50px 25px;
		padding: 50px 20px;

		.avatar {
			display: flex;
			align-items: center;
			flex-direction: row;
			justify-content: center;

			& > div {
				margin: 0 6px;
			}

			& > div:nth-child(2) {
				line-height: 1.5;
				font-size: 18px;

				& > div:nth-child(2) {
					font-weight: 600;
					font-size: 22px;

					span {
						font-size: 14px;
						color: var(--el-color-info);
					}
				}
			}
		}

		.note {
			margin: 5px 0 10px 0;
		}

		.detail {
			& > div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 2.3;
				flex-wrap: wrap;
				flex-direction: row;

				& > div:nth-child(2) {
					font-weight: 600;
				}
			}
		}
	}
}
</style>
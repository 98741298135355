<template>
	<div id="tu-tutor-availability">
		<el-dialog
			v-model="dialogVisible"
			width="45%"
			destroy-on-close>
			<div v-if="availability" class="operation-header">
				<div class="time-interval">
					<el-icon @click="getTutorAvailability(-1)"><i class="fa fa-chevron-left"></i></el-icon>
					<el-icon @click="getTutorAvailability(1)"><i class="fa fa-chevron-right"></i></el-icon>
					<div class="show-date">
						{{ availabilityData.showDate }}
					</div>
				</div>
				<div>
					<el-button-group class="ml-4">
						<el-button type="primary" size="small" :plain="timezone==1?false:true" @click="selectTimeZone(1)">{{ $t('China time') }}</el-button>
						<el-button type="primary" size="small" :plain="timezone==2?false:true" @click="selectTimeZone(2)">{{ $t('UK time') }}</el-button>
					</el-button-group>
				</div>
			</div>
			<div v-if="availability" class="tu-time-content">
				<div class="time-datetime">
					<div class="header" v-for="item, index in availability" :key="index">
						<span class="day">{{ item.day }}</span>
						<span class="week">{{ item.ddd }}</span>
					</div>
				</div>
				<div class="time-datetime" v-for="item, index in availabilityMax" :key="index">
					<div class="time-wrap" v-for="it, i in availability">
						<div>
							<span v-if="!!!it.children[index]" class="nothing">No</span>
							<span v-else-if="it.children[index].ok==1" class="booked">Booked</span>
							<span v-else-if="it.children[index].ok==2" class="rested">Leave</span>
							<span v-else-if="it.children[index].ok==3" class="nobook">{{ it.children[index].hi }}</span>
							<span v-else @click="handleRequestLesson(it.children[index]._t)">{{ it.children[index].hi }}</span>
						</div>
					</div>
				</div>
			</div>
			<div v-else>
				<el-empty :image-size="200"></el-empty>
			</div>
	  	</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TimeControl from '@/utils/time-control'
import TeacherApi from '@/api/teacher'

export default {
	name: 'TutorAvailability',
	components: {
		
	},
	props: {
		
	},
	data() {
		return {
			dialogVisible: false,
			tutor_id: 0,
			availabilityData: [],
			availabilityMax: 0,
			timezone: 0
		}
	},
	created() {
		this.timezone = TimeControl.getTimeZone()
		if(!!this.userInfo) {
			this.timezone = this.userInfo.timezone;
		}
		this.timezone = TimeControl.getZoneCode(this.timezone);
	},
	computed: {
		...mapGetters([
			'userInfo'
		]),
		availability() {
			if(Object.keys(this.availabilityData).length == 0) {
				return [];
			}
			let timezone = this.timezone == 1 ? 'Asia/Shanghai' : (this.timezone == 2 ? 'Europe/London' : '');
			this.availabilityData.startDate = TimeControl.getFormatTime(this.availabilityData.start, "DD/MM/YYYY", timezone);
			this.availabilityData.endDate = TimeControl.getFormatTime(this.availabilityData.end, "DD/MM/YYYY", timezone);
			this.availabilityData.showDate = TimeControl.getFormatTime(this.availabilityData.start, "DD MMM", timezone) + ' - ' + TimeControl.getFormatTime(this.availabilityData.end, "DD MMM, YYYY", timezone);
			let availability = {};
			let di = 0;
			while(di < 8) {
				let ymd = TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "DD/MM/YYYY", timezone);
				availability[ymd] = {
					day: TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "DD", timezone),
					ddd: TimeControl.getFormatTimeAdd(this.availabilityData.start, di*86400, "ddd", timezone),
					children: []
				}
				if(ymd == this.availabilityData.endDate) {
					break;
				}
				di += 1;
			}
			if(!!this.availabilityData.times) {
				for(let i in this.availabilityData.times) {
					let ymd = TimeControl.getFormatTime(this.availabilityData.times[i].time, "DD/MM/YYYY", timezone);
					if(!!availability[ymd]) {
						availability[ymd].children.push({
							hi: TimeControl.getFormatTime(this.availabilityData.times[i].time, "HH:mm", timezone),
							ok: this.availabilityData.times[i].is_ok,
							_t: this.availabilityData.times[i].time
						});
					}					
				}
			}
			for(let k in availability) {
				if(availability[k].children.length > this.availabilityMax) {
					this.availabilityMax = availability[k].children.length;
				}
			}
			return availability;
		}
	},
	methods: {
		// 打开预约课程弹框
		open(value) {
			this.tutor_id = value;
			this.getTutorAvailability();
			this.dialogVisible = true;
		},
		close() {			
			this.dialogVisible = false;
		},
		// 关闭是判断，是否提示
		handleClose() {
			this.close();
		},
		selectTimeZone(value) {
			this.timezone = value;
		},
		getTutorAvailability(index) {
			if(!this.tutor_id) {
				return ;
			}
			let tz = this.availabilityData ? this.availabilityData.tz : 0;
			if(!!index) {
				tz = tz + index*1;
				if(tz < 0) {
					return false;
				}
			}
			let params = {
				tz: tz,
				uid: this.tutor_id
			}
			TeacherApi.zoneAvailability(params).then(res => {
				this.availabilityData = res.data;
			})
		},
		handleRequestLesson(value) {
			this.$emit('change', value);
			this.close();
		}
	}
}
</script>
<style lang="less">
#tu-tutor-availability {
	.operation-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.time-interval {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		i.el-icon {
			border: 2px solid var(--el-color-primary);
			padding: 5px;
			color: var(--el-color-primary);
			margin: 0 2px;
			cursor: pointer;
		}

		i.el-icon:hover {
			background: var(--el-color-primary);
			color: var(--el-color-white);
		}

		.show-date {
			font-size: 18px;
			font-weight: 500;
			padding: 0 15px;
		}
	}

	.tu-time-content {
		// background: var(--el-color-info-lighter);
		border: 1px solid var(--el-border-color-lighter);
		margin-top: 15px;

		.time-datetime:first-child {
			line-height: 42px;
			background: var(--el-color-white);
		}

		.time-datetime {
			display: flex;
	    flex-direction: row;
	    align-items: center;
	    // margin-top: 12px;
	    width: 100%;

	    .header, .time-wrap {
	    	width: calc(100% / 7);
	    	text-align: center;
	    }

	    .header {
		    font-weight: 600;

				.day {
					font-size: 19px;
					color: #424B5B;
					letter-spacing: -0.28px;
				}

				.week {
					font-size: 12px;
					color: #424B5B;
					letter-spacing: -0.18px;
					margin-left: 5px;
				}
			}

			.time-wrap {
				-moz-user-select: none; /*火狐*/
				-webkit-user-select: none; /*webkit浏览器*/
				-ms-user-select: none; /*IE10*/
				-khtml-user-select: none; /*早期浏览器*/
				-o-user-select: none; /* Opera*/
				user-select: none;
				span {
					display: block;
				  background: gray;
					margin: 4px 12px;
					color: #FFF;
					padding: 4px 0;
					cursor: pointer;
				}

				span.nothing {
					background: var(--el-color-info-lighter);
				}

				span.booked {
					background: var(--el-color-primary);
				}

				span.rested {
					background: var(--el-color-error);
				}

				span.nobook {
					background: var(--el-color-info-light);
				}


				// span:first-child {
				// 	color: var(--el-color-primary);
				// }

				// span:last-child {
				// 	color: #F40002;
				// }
			}
		}
	}
}
</style>